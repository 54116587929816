$datatable-header-border-color: $siemens-deep-blue-200;
$datatable-header-background-color: $element-ui-4;
$text-color-black: $siemens-black;

.ngx-datatable.table-element .datatable-header {
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $datatable-header-border-color;
  background-color: $datatable-header-background-color;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  color: $text-color-black;
}

.ngx-datatable .datatable-body .datatable-body-row {
  cursor: pointer;
}

.ngx-datatable .datatable-row-center {
  min-height: 60px;
  display: flex;
}
