// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';
@import './override-ngx-datatable';

.si-about-content {
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: unset;
  }
}
